import 'deepnotes-editor/dist/deepnotes-editor.css'
import '@reach/menu-button/styles.css'
import './assets/main.css'
import 'firebase/firestore'
import 'firebase/storage'

import { message, Modal, Tree } from 'antd'
import prompt from 'antd-prompt'
import Editor, { createDecorators } from 'deepnotes-editor'
import { convertFromRaw, convertToRaw, EditorState, genKey } from 'draft-js'
import * as firebase from 'firebase/app'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ThemeSwitcherProvider, useThemeSwitcher } from 'react-css-theme-switcher'
import ReactDOM from 'react-dom'
import Dropzone from 'react-dropzone'
import { Helmet } from 'react-helmet'
import LoadingBar from 'react-top-loading-bar'
import * as securePin from 'secure-pin'
import useQueryString from 'use-query-string'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button'

import { ReactComponent as ArrowLeftIcon } from './assets/arrow-left.svg'
import { ReactComponent as CogIcon } from './assets/cog.svg'
import { ReactComponent as MenuIcon } from './assets/menu.svg'
import { ReactComponent as MoonIcon } from './assets/moon.svg'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import { ReactComponent as SunIcon } from './assets/sun.svg'
import Shortcuts from './components/shortcuts'
import defaultContent from './default-content.json'
import useLocalStorage from './hooks/useLocalStorage'
import { deepEqual, throttle } from './utils'

firebase.initializeApp({
  apiKey: 'AIzaSyBtMhEIL5Q_bTWribGP38SnaOGer9wWwBs',
  authDomain: 'docier-f822e.firebaseapp.com',
  databaseURL: 'https://docier-f822e.firebaseio.com',
  projectId: 'docier-f822e',
  storageBucket: 'docier-f822e.appspot.com',
  messagingSenderId: '789298796328',
  appId: '1:789298796328:web:2ef1d34fef6543c07a221d'
})

const db = firebase.firestore().collection('docs')
const storage = firebase.storage()

const antdThemes = {
  dark: `${process.env.PUBLIC_URL}/antd.dark.min.css`,
  light: `${process.env.PUBLIC_URL}/antd.compact.min.css`
}

type LoadingBarRef = {
  add(value: number): void
  decrease(value: number): void
  continuousStart(startingValue?: number, refreshRate?: number): void
  staticStart(startingValue: number): void
  complete(): void
}

function AppContainer(props: any) {
  const [isDarkMode] = useLocalStorage('isDarkMode', false)

  return (
    <ThemeSwitcherProvider themeMap={antdThemes} defaultTheme={isDarkMode ? 'dark' : 'light'}>
      {props.children}
    </ThemeSwitcherProvider>
  )
}

function App() {
  const loadingBarRef = useRef<LoadingBarRef>(null)

  const [content, setContent] = useState(defaultContent)
  const [editorKey, setEditorKey] = useState(genKey())
  const [isReady, setIsReady] = useState(false)

  // localstorage config
  const [isDarkMode, setIsDarkMode] = useLocalStorage('isDarkMode', false)
  const [isShortcutPanelOpen, setIsShortcutPanelOpen] = useLocalStorage('isShortcutPanelOpen', true)
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage('isSidebarOpen', true)
  const [pin, setPin] = useLocalStorage('pin', undefined)

  // use the querystring to persist the current search query and zoom id
  const [{ q, zoom }, setQuery] = useQueryString(window.location, updateQuerystring)

  let editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(content))), createDecorators())

  const [toc, setToc] = useState<any>(convertContentToTOC())

  // expand the first toc level
  const defaultExpandedKeys = getDefaultTOCExpandedKeys()

  // generate a unique pin code for this browser
  if (!pin) {
    const charSet = new securePin.CharSet()
    charSet.addLowerCaseAlpha().addUpperCaseAlpha().removeChar('aAo0').randomize()

    setPin(securePin.generateStringSync(10, charSet))
  }

  const { switcher, themes } = useThemeSwitcher()

  // update toc when content changes
  useEffect(() => {
    setToc(convertContentToTOC())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(queryDB, [pin])

  // ensure editor has the correct classes
  useEffect(setEditorClassName, [isDarkMode])

  function confirm(title: string = 'Confirm', content: string = 'Are you sure?', onOk: (...args: any[]) => any) {
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      content,
      onOk
    })
  }

  function convertContentToTOC() {
    let toc: any[] = []
    let ht = Object.create(null)

    if (content.blocks) {
      content.blocks.forEach((block: any) => (ht[block.key] = { title: block.text || (block.key === 'root' ? 'Home' : ''), key: block.key, children: [] }))

      content.blocks.forEach((block: any) => {
        if (block.data.parentId) ht[block.data.parentId].children.push(ht[block.key])
        else toc.push(ht[block.key])
      })
    }

    return toc
  }

  function getDefaultTOCExpandedKeys() {
    let keys: string[] = []

    if (toc && toc.length === 1) {
      keys.push(toc[0].key)

      if (toc[0].children && toc[0].children.length > 0) {
        toc[0].children.forEach((child: any) => {
          keys.push(child.key)
        })
      }
    }

    return keys
  }

  function handleDeleteAllClick() {
    confirm('Delete All?', 'Are you sure? Please note this action is irreversible.', () => {
      updateContent(defaultContent)
    })
  }

  function handleEditorChange(state: EditorState) {
    document.title = 'Docier - Compile thoughts into ideas'
    const raw: any = convertToRaw(state.getCurrentContent())

    if (isReady) {
      if (!deepEqual(raw, content)) {
        setDB(raw)
      }
    }
  }

  function handleEditorRootChange(itemId: string) {
    setQuery({ zoom: itemId })
  }

  function handlePinCopied() {
    message.info('Your Docier pin has been copied to the clipboard.')
  }

  function handleSearchChange(e: React.FormEvent<HTMLInputElement>) {
    setQuery({ q: e.currentTarget.value })
  }

  async function handleSetPin() {
    try {
      const newPin = await prompt({
        title: 'Set Docier Pin',
        placeholder: 'Pin (between 6 and 20 characters)',
        rules: [
          {
            required: true,
            message: 'You must enter a valid pin',
            max: 20,
            min: 6
          }
        ]
      })

      setPin(newPin)
    } catch (e) {
      // ignore
    }
  }

  function handleShortcutPanelToggle() {
    setIsShortcutPanelOpen(!isShortcutPanelOpen)
  }

  function handleSidebarToggle() {
    setIsSidebarOpen(!isSidebarOpen)
  }

  function handleThemeToggle() {
    setIsDarkMode(!isDarkMode)
    switcher({ theme: isDarkMode ? themes.light : themes.dark })
  }

  function handleTOCSelect(selectedKeys: (string | number)[]) {
    if (selectedKeys && handleTOCSelect.length === 1) {
      setQuery({ zoom: selectedKeys[0] })
    }
  }

  function queryDB() {
    if (pin) {
      db.doc(pin)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data()

            if (!deepEqual(content, data)) {
              updateContent(data)
            }
          } else {
            if (!deepEqual(content, defaultContent)) {
              updateContent(defaultContent)
            }
          }

          setTimeout(() => {
            setIsReady(true)
          }, 500)
        })

      // subscribe to any server updates (file text etc)
      db.doc(pin).onSnapshot(doc => {
        const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'

        if (source === 'Server') {
          const data = doc.data()

          if (data) {
            updateContent(data, false)
          }
        }
      })
    }
  }

  const setDB = throttle((raw: any) => {
    if (pin) {
      db.doc(pin).set(raw)
    }
  }, 500)

  function setEditorClassName() {
    const editorEl = document.getElementsByClassName('editor')[0]

    if (editorEl) {
      if (isDarkMode) {
        editorEl.classList.remove('deepnotes-editor-theme-light')
        editorEl.classList.add('deepnotes-editor-theme-dark')
      } else {
        editorEl.classList.add('deepnotes-editor-theme-light')
        editorEl.classList.remove('deepnotes-editor-theme-dark')
      }
    }
  }

  function updateContent(raw: any, resetZoom: boolean = true) {
    setContent(raw)

    if (resetZoom) {
      setQuery({ zoom: undefined })
    }

    editorState = EditorState.createWithContent(convertFromRaw(raw), createDecorators())
    setEditorKey(genKey())
  }

  function updateQuerystring(path: string | null) {
    window.history.pushState(null, document.title, path)
  }

  const dropzoneProps = {
    accept: 'application/pdf',
    multiple: false,
    onDropAccepted: (files: any[]) => {
      if (files.length === 1) {
        const file = files[0]
        const fileName = file.name
        const key = genKey()

        if (loadingBarRef.current) {
          loadingBarRef.current.continuousStart(10)
        }

        storage
          .ref(`/files/${pin}/${key}/${genKey()}/${fileName}`)
          .put(file)
          .then(() => {
            if (loadingBarRef.current) {
              loadingBarRef.current.complete()
            }

            let updatedContent = { ...content }
            updatedContent.blocks.push({ data: { parentId: zoom || 'root' }, entityRanges: [], inlineStyleRanges: [], key, depth: 1, text: fileName, type: 'unordered-list-item' })

            updateContent(updatedContent, false)
          })
      }
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>Docier - Compile thoughts into ideas</title>
        <body className={isDarkMode ? 'theme-dark' : 'theme-light'} />
      </Helmet>
      <LoadingBar color="#87d068" ref={loadingBarRef} />
      <div className="flex w-screen antialiased text-copy-primary bg-background-primary">
        {isSidebarOpen && (
          <div>
            <div className="w-64 h-full py-2 shadow-inner bg-background-secondary">
              <div className="flex justify-end mr-3">
                <button aria-label="Hide sidebar" className="icon-button hover:bg-background-primary" onClick={handleSidebarToggle}>
                  <ArrowLeftIcon />
                </button>
              </div>
              <div className="p-4">
                <Tree defaultExpandedKeys={defaultExpandedKeys} onSelect={handleTOCSelect} selectedKeys={[zoom || 'root']} treeData={toc}></Tree>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col flex-1 h-screen App">
          <div className="flex-shrink-0">
            <header className="z-10 flex flex-no-wrap items-center justify-between px-3 py-3 border border-border-color-primary sm:flex-wrap sm:px-6 sm:py-3">
              <div className="flex flex-1">
                {!isSidebarOpen && (
                  <button aria-label="Show sidebar" className="icon-button hover:bg-background-primary" onClick={handleSidebarToggle}>
                    <MenuIcon />
                  </button>
                )}
              </div>
              <div className="flex items-center justify-end flex-1 space-x-5">
                <button aria-label="Switch theme" className="icon-button" onClick={handleThemeToggle}>
                  {!isDarkMode && <MoonIcon />}
                  {isDarkMode && <SunIcon />}
                </button>
                <div className="relative flex">
                  <SearchIcon />
                  <input
                    type="text"
                    className="flex w-32 px-8 py-1 text-sm border-2 border-background-secondary rounded-full outline-none text-copy-primary bg-background-primary sm:w-48 focus:w-48 focus:border-background-tertiary focus:border-2 ease-in-out duration-100"
                    placeholder="Search"
                    onChange={handleSearchChange}
                    value={q}></input>
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <div>
                    <Menu>
                      <MenuButton>
                        <CogIcon />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onSelect={() => {}}>
                          <CopyToClipboard text={pin} onCopy={handlePinCopied}>
                            <span>
                              Your Docier Pin: <b>{pin}</b>
                            </span>
                          </CopyToClipboard>
                        </MenuItem>
                        <MenuItem onSelect={handleSetPin}>Set Pin</MenuItem>
                        <div className="w-full border-t border-background-tertiary my-1" style={{ opacity: 0.7 }}></div>
                        <MenuItem onSelect={handleSidebarToggle}>{isSidebarOpen ? 'Hide' : 'Show'} TOC panel</MenuItem>
                        <MenuItem onSelect={handleShortcutPanelToggle}>{isShortcutPanelOpen ? 'Hide' : 'Show'} shortcut panel</MenuItem>
                        <div className="w-full border-t border-background-tertiary my-1" style={{ opacity: 0.7 }}></div>
                        <MenuItem onSelect={handleDeleteAllClick}>Delete all</MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="flex flex-1 w-full mx-auto overflow-auto">
            <main className="main-content">
              <Dropzone {...dropzoneProps}>
                {({ getInputProps, getRootProps }) => (
                  <div {...getRootProps({ onClick: event => event.stopPropagation() })}>
                    <input {...getInputProps()} />
                    <Editor initialEditorState={editorState} initialZoomedInItemId={zoom || 'root'} key={editorKey} onChange={handleEditorChange} onRootChange={handleEditorRootChange} searchText={q} />
                  </div>
                )}
              </Dropzone>
            </main>
            <div className={'right-0 min-h-full border border-t-0 border-b-0 border-background-secondary border-opacity-25 transition-all duration-200 hidden ' + (isShortcutPanelOpen ? 'lg:block' : 'lg:hidden')}>
              <Shortcuts />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const rootElement = document.getElementById('root')

ReactDOM.render(
  <AppContainer>
    <App />
  </AppContainer>,
  rootElement
)
