import React from 'react'

function Shortcuts() {
  return (
    <div className="w-64 p-6 overflow-hidden" style={{ height: 'calc(100vh - 60px)' }}>
      <h4>Shortcuts</h4>
      <ul>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Zoom in</span>
          <span className="text-copy-tertiary">Ctrl/⌘.</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Zoom out</span>
          <span className="text-copy-tertiary">Ctrl/⌘,</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Expand</span>
          <span className="text-copy-tertiary">Ctrl/⌘↓</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Collapse</span>
          <span className="text-copy-tertiary">Ctrl/⌘↑</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Indent</span>
          <span className="text-copy-tertiary">(Tab) ⇥</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Outdent</span>
          <span className="text-copy-tertiary">(shift-tab) ⇧⇥</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Move up</span>
          <span className="text-copy-tertiary">Ctrl/⌘⇧↑</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Move down</span>
          <span className="text-copy-tertiary">Ctrl/⌘⇧↓</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Complete</span>
          <span className="text-copy-tertiary">Ctrl/⌘↵</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Delete</span>
          <span className="text-copy-tertiary">Ctrl/⌘⇧⌫</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Search</span>
          <span className="text-copy-tertiary">EscEsc</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Navigate home</span>
          <span className="text-copy-tertiary">Ctrl/⌘'</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Bold</span>
          <span className="text-copy-tertiary">Ctrl/⌘B</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Italic</span>
          <span className="text-copy-tertiary">Ctrl/⌘I</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Underline</span>
          <span className="text-copy-tertiary">Ctrl/⌘U</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Code</span>
          <span className="text-copy-tertiary">Ctrl/⌘J</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Soft newline</span>
          <span className="text-copy-tertiary">Alt↵</span>
        </li>
        <li className="flex justify-between w-full mt-4 text-xs">
          <span>Toggle sidebar</span>
          <span className="text-copy-tertiary">Ctrl/⌘?</span>
        </li>
      </ul>
    </div>
  )
}

export default Shortcuts
